<template>
  <b-card>
    <form-component @onSubmit="onSubmit" :technician="technician" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/technicians/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, update } from '@/http/technicians';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      technician: {
        id: '',
        county_id: '',
        about_us_id: false,
        name: '',
        surname: '',
        phone: '',
        email: '',
        postal_code: '',
        city: '',
      },
    };
  },

  async mounted() {
    await this.fetchTechnician();
  },

  methods: {
    async fetchTechnician() {
      try {
        const { data } = await show(this.$route.params.id);

        this.technician = data.data;
        this.$set(this.technician, 'county_id', this.technician.county);
      } catch (error) {
        this.showErrorNotification('Problem z pobraniem danych', 'Nie udało się pobrać danych serwisanta.');
      }
    },
    async onSubmit(form) {
      try {
        await update(this.technician.id, form);

        this.showSuccessNotification('Dane zapisane', 'Serwisant został zaktualizowany.');
        this.$router.push({ name: 'technicians-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas dodawania serwisanta. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
